.project {
  margin-bottom: var(--small-padding);
  & p:first-child{
    color: var(--primary-text-color);
  }
  &__title {
    font-family: "Playfair Display", serif;
    font-weight: 500;
    text-align: center;
    margin-bottom: var(--small-padding);
  }

  &__icons {
    & > * {
      margin-right: var(--small-padding);
    }

    margin-top: var(--small-padding);
  }

  &__description {
    display: flex;
    align-items: flex-end;
    position: relative;
    margin-bottom:-2rem;
  }
}
.description-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70%;
  padding: var(--small-padding);
  background: rgba(36, 36, 36, 0.25);
  box-shadow: 0 8px 32px 0 rgba(27, 34, 126, 0.464);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border-radius: 10px;
  border: 1px solid rgba(61, 61, 61, 0.18);
  align-content: flex-end;
}
.video {
  display: grid;
  gap: var(--small-padding);
  position: relative;
}

@media only screen and (min-width: 80rem) {
  .project {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1.5fr 1fr;
    &__thumbnail {
      grid-column: 1 / span 1;
    }
    &__description {
      grid-column: 2 / span 1;
      margin-left: -5rem;
      
    }
  }
}

ul {
  color: var(--secondary-text-color);
}

.nav {
  padding-top: 2rem;
  &__medium {
    display: none;

    & a {
      margin-right: var(--small-padding);
    }
  }

  &__small {
    position: absolute;
    top: 5rem;
    width: 20rem;
    text-align: center;
    left: 0;
    padding-top: var(--small-padding);
    height: 100vh;
    z-index: 1000;
    background: rgba(32, 32, 32, 0.125);
    box-shadow: 0 8px 32px 0 rgba(14, 14, 14, 0.797);
    backdrop-filter: blur(4px);
    border:1px solid rgba(71, 71, 71, 0.233);
 
    & > li:first-child {
      margin-top: var(--standard-padding);
    }
  }

  &__menu {
    position: relative;
  }
}

@media only screen and (min-width: 60rem) {
  .nav {
    &__menu {
      display: none;
    }
    &__medium {
      display: block;
      display: flex;
      justify-content: space-between;
    }

    &__small {
      display: none;
    }
  }
}

.skill {
  &__button > *:not(:last-child) {
    margin-right: var(--small-padding);
    margin-bottom: var(--small-padding);
  }
  &__title {
    text-align: center;
    font-weight: 500;
  }

  &__icons {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: var(--standard-padding);

    & > * {
      padding: var(--small-padding);
      margin-bottom: 1rem;
      box-shadow: 0 8px 32px 0 rgba(173, 32, 224, 0.221);

      border: 1px solid rgba(86, 86, 86, 0.077);
      border-radius: 2rem;
      cursor: pointer;

      &:hover {
        box-shadow: 0 8px 32px 0 rgba(173, 32, 224, 0.329);
      }
    }
  }

  &__content {
    text-align: center;
    margin-top: var(--small-padding);
  }

  &__button {
    &:not(:last-child) {
     
      margin-bottom: var(--small-padding);
    }
  }

  @mixin glow-stick {
    background: linear-gradient(var(--primary-purple), var(--primary-blue));
    box-shadow: 0px 0px 21px 5px rgba(33, 211, 231, 0.266);
  }

  &__stick {
    &--1 {
      width: 0.1rem;
      height: 9rem;
      @include glow-stick;
    }

    &--2 {
      position: absolute;
      width: 9rem;
      height: 0.1rem;
      bottom: 0;
      left: 0;
      @include glow-stick;
    }

    &-wrapper {
      position: relative;
    }
  }
}
@media only screen and (min-width: 40rem) {
 
  .skill__button {
    &:not(:last-child) {
      margin-right: var(--small-padding);
   
    }
  }
}
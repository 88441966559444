.landing {
  &__text {
    height: 80vh;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 50vw;
    margin-top: var(--standard-margin);

    & h1 {
      font-family: "Playfair Display", serif;
      font-weight: 500;
    }

    & p {
      letter-spacing: 1.2;
    }
  }

  &__name {
    color: var(--primary-orange);
    animation: infinite-color 5s infinite;
 
  }

  &__before-name {
    font-size: clamp(3.5rem, 4vw, 5rem);
  }

  &__button {
    margin-bottom: var(--small-padding);
  }

  &__download {
    color: var(--primary-red-orange);
    margin-top: 1.5rem;
    margin-left: var(--small-padding);

    &:hover {
      color: var(--primary-red-orange);
    }
  }
}

.social-media {
  position: absolute;
  right: 2rem;
  bottom: 1%;

  &__stick {
    width: 0.1rem;
    height: 8rem;
    background: linear-gradient(var(--primary-purple), var(--primary-blue));
    box-shadow: 4px 15px 21px 5px rgba(33, 211, 231, 0.281);

    &-wrapper {
      width: 2rem;
      height: 8rem;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }
  }

  &__icons {
    display: flex;
    flex-direction: column;

    & > * {
      transition: all 0.1s ease;
      &:hover {
        transform: scale(1.1);
      }
    }

    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

@keyframes infinite-color {
  0% {
    color: var(--primary-orange);
  }
  50% {
    color: var(--primary-red-orange);
  }
  100% {
    color: var(--primary-orange);
  }
}

@media only screen and (min-width: 70rem) {
  .social-media {
    right: 12rem;
  }

  .landing__text {
    margin-top: 0;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Playfair+Display:wght@400;500;700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
}

body,
button {
  color: rgb(234, 234, 234);
  font-family: "Open Sans", sans-serif;
  font-size: 62.5%;
  line-height: 1.5;
  color: var(--secondary-text-color);
}

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --secondary-text-color: #a8abb9;
  --primary-orange: #fc6714;
  --primary-red-orange: #cd382c;
  --small-padding: 2rem;
  --primary-text-color: white;
  --standard-padding: 4rem;
  --primary-purple: #d65fe1;
  --primary-blue: #50f1f8;
  --section-margin: 8rem;
  --secondary-font: "Playfair Display", serif;
}

.secondary-text {
  color: var(--secondary-text-color);
}
.font-weight-normal {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 700;
}

.secondary-font {
  font-family: "Playfair Display", serif;
}

.primary-text {
  color: var(--primary-text-color);
}

.background {
  background-image: url("./img/background.png");
  background-repeat: no-repeat;
}

.small-margin {
  margin-top: var(--small-padding);
}

.section-margin {
  margin-top: var(--section-margin);
}

.color-orange {
  color: var(--primary-orange);
}

.container {
  margin: 0 clamp(1.2rem, 10%, 26rem);
}

h1 {
  font-size: clamp(3.5rem, 4vw, 6.5rem);
}

h2 {
  font-size: clamp(3.25rem, 3.25vw, 5rem);
}

h3 {
  font-size: clamp(3rem, 3vw, 4.5rem);
}
h4 {
  font-size: clamp(2.75rem, 2.75vw, 4rem);
}

h5 {
  font-size: clamp(2.5rem, 2.5vw, 3.5rem);
}
p,
button,
a,
li,
label {
  font-size: clamp(1rem, 1.2vw, 1.1rem);
}

ul {
  list-style-type: none;
}

a {
  color: var(--secondary-text-color) !important;

  &:hover {
    color: var(--primary-text-color) !important;
  }

  &:focus {
    outline: 0;
  }
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

@mixin button {
  padding: 1rem 2rem;
  transition: all 0.3s ease;
  color: var(--primary-text-color);
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.normal-button {
  background: linear-gradient(
    to right,
    var(--primary-orange),
    var(--primary-red-orange)
  );
  border: none;

  @include button;
}

.transparent-button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--secondary-text-color);
  cursor: pointer;

  &:hover {
    color: white;
  }
}

.border-button {
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      var(--primary-blue),
      var(--primary-purple)
    )
    1;
  @include button;
  box-shadow: 0px 3px 21px 5px rgba(231, 26, 214, 0.212);

  &:hover {
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0px 3px 21px 5px rgba(231, 26, 214, 0.348);
    }
  }
}

// pop up
.overlay {
  background-color: rgba(31, 31, 31, 0.081);
  position: relative;
}

a {
  color: white;
  text-decoration: none;
   cursor: pointer;
}
